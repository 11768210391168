import './FirstScreen.scss';

const FirstScreen = ({ title, text, image, color, onFormClick, buttonText = 'Contact Us' }) => {
  return (
    <div className='firstScreen'>
      <div className='firstScreenData'>
        <h1 className='firstScreenTitle'>{title}</h1>
        <div className='firstScreenText'>{text}</div>
        <div className='firstScreenButton' style={{ backgroundColor: color }} onClick={onFormClick}>
          {buttonText}
          <svg className='firstScreenButtonIcon' viewBox='0 0 36 26' fill='none'>
            <path d='M22.1326 1.06787C22.4336 5.12205 25.4284 13.1836 34.9998 12.9965' stroke='black' stroke-width='3.3' />
            <path d='M22.1326 24.9316C22.4336 20.8775 25.4284 12.8159 34.9998 13.003' stroke='black' stroke-width='3.3' />
            <path d='M35 12.9995L0.000244141 12.9995' stroke='black' stroke-width='3.3' />
          </svg>
        </div>
      </div>
      <div className='firstScreenImage'>
        <img src={image} />
      </div>
    </div>
  );
};

export default FirstScreen;
