import './Header.scss';
import Logo from '../../assets/NewLogo.svg';
import LogoText from '../../assets/Pioneers.svg';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = ({ onFormClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    setIsMenuOpen(false);
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1440);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1440);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const renderHeaderLinks = () => {
    return (
      <>
        <div className='headerLinks'>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/');
            }}
            className={cn('headerLink', window.location.pathname === '/' && 'headerLinkActive')}
          >
            About
          </span>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/education');
            }}
            className={cn('headerLink', window.location.pathname === '/education' && 'headerLinkActive')}
          >
            Education
          </span>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/engineering');
            }}
            className={cn('headerLink', window.location.pathname === '/engineering' && 'headerLinkActive')}
          >
            Engineers
          </span>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/marketers');
            }}
            className={cn('headerLink', window.location.pathname === '/marketers' && 'headerLinkActive')}
          >
            Marketers
          </span>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/assistants');
            }}
            className={cn('headerLink', window.location.pathname === '/assistants' && 'headerLinkActive')}
          >
            Assistants
          </span>
          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/nurses');
            }}
            className={cn('headerLink', window.location.pathname === '/nurses' && 'headerLinkActive')}
          >
            Nurses
          </span>

          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/sales');
            }}
            className={cn('headerLink', window.location.pathname === '/sales' && 'headerLinkActive')}
          >
            Sales
          </span>

          <span
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/blog');
            }}
            className={cn('headerLink', window.location.pathname.includes('/blog') && 'headerLinkActive')}
          >
            Blog
          </span>
        </div>
        <span className='headerContact' onClick={onFormClick}>
          Contact Us
        </span>
      </>
    );
  };

  return (
    <div className={cn('header')}>
      <div className='headerLogo'>
        <img src={Logo} alt='Logo' />
        <img src={LogoText} alt='Logo' />
        {window.location.pathname === '/education' && <span className='educationTag'>Education</span>}
        {window.location.pathname === '/engineering' && <span className='engineeringTag'>Engineers</span>}
        {window.location.pathname === '/marketers' && <span className='marketersTag'>Marketers</span>}
        {window.location.pathname === '/assistants' && <span className='assistantsTag'>Assistants</span>}
        {window.location.pathname === '/nurses' && <span className='nursesTag'>Nurses</span>}
      </div>
      {isMobile ? (
        <span className='headerMenu' onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M18 6L6 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M6 6L18 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
          ) : (
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M3 12H21' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M3 6H21' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M3 18H21' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
          )}
        </span>
      ) : (
        renderHeaderLinks()
      )}
      {isMenuOpen && <div className='phoneHeaderMenu'>{renderHeaderLinks()}</div>}
    </div>
  );
};

export default Header;
