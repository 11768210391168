import Layout from './app/components/Layout';
import LayoutOld from './app/components/LayoutOld';
import BlogPage from './app/pages/BlogPage';
import BlogPost from './app/pages/BlogPost';
import { createBrowserRouter } from 'react-router-dom';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Layout color='#0EFFD4' type='main' />,
  },
  {
    path: '/education',
    element: <Layout color='#EE6328' type='edu' />,
  },
  {
    path: '/engineering',
    element: <Layout color='#4E75FF' type='eng' />,
  },
  {
    path: '/marketers',
    element: <Layout color='#C759D9' type='mar' />,
  },
  {
    path: '/assistants',
    element: <Layout color='#FF7575' type='ass' />,
  },
  {
    path: '/nurses',
    element: <Layout color='#71C348' type='nur' />,
  },
  {
    path: '/sales',
    element: <Layout color='#15B0E1' type='sal' />,
  },
  {
    path: '/investors',
    element: <LayoutOld />,
  },
  {
    path: '/blog',
    element: <BlogPage />,
  },
  {
    path: '/blog/:id',
    element: <BlogPost />,
  },
]);

export default routes;
